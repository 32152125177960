<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <a href="/" class="text-decoration-none white--text"
            ><span class="logo-normal">GOPAX Admin</span></a
          >
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import { getMenus } from "@/pages";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    const exampleItems = [
      {
        titleKey: "examples-hypen",
      },
      {
        titleKey: "hello",
        to: "/hello",
      },
      {
        titleKey: "page.dashboard",
        to: "/examples/dashboard",
      },
      {
        group: "/examples/pages",
        icon: "mdi-image",
        titleKey: "pages",
        children: [
          {
            titleKey: "timeline",
            to: "timeline",
          },
          {
            titleKey: "login",
            to: "login",
          },
          {
            titleKey: "lock",
            to: "lock",
          },
          {
            titleKey: "user",
            to: "user",
          },
          {
            titleKey: "error",
            to: "404",
          },
        ],
      },
      {
        group: "/examples/components",
        icon: "mdi-view-comfy",
        titleKey: "components",
        children: [
          {
            titleKey: "multi",
            group: "",
            children: [
              {
                titleKey: "example",
                href: "#",
              },
            ],
          },
          {
            titleKey: "buttons",
            to: "buttons",
          },
          {
            titleKey: "grid",
            to: "grid-system",
          },
          {
            titleKey: "tabs",
            to: "tabs",
          },
          {
            titleKey: "notifications",
            to: "notifications",
          },
          {
            titleKey: "icons",
            to: "icons",
          },
          {
            titleKey: "typography",
            to: "typography",
          },
        ],
      },
      {
        group: "/examples/forms",
        icon: "mdi-clipboard-outline",
        titleKey: "forms",
        children: [
          {
            titleKey: "rforms",
            to: "regular",
          },
          {
            titleKey: "eforms",
            to: "extended",
          },
          {
            titleKey: "vforms",
            to: "validation",
          },
          {
            titleKey: "wizard",
            to: "wizard",
          },
        ],
      },
      {
        group: "/examples/tables",
        icon: "mdi-grid",
        titleKey: "tables",
        children: [
          {
            titleKey: "rtables",
            to: "regular-tables",
          },
          {
            titleKey: "etables",
            to: "extended-tables",
          },
          {
            titleKey: "dtables",
            to: "data-tables",
          },
        ],
      },
      {
        icon: "mdi-widgets",
        titleKey: "widgets",
        to: "/examples/widgets",
      },
      {
        icon: "mdi-chart-timeline-variant",
        titleKey: "charts",
        to: "/examples/charts",
      },
      {
        icon: "mdi-calendar-range",
        titleKey: "calendar",
        to: "/examples/calendar",
      },
    ];

    const items = [];
    items.push(...getMenus());

    const devMode = process.env.NODE_ENV === "development";
    if (devMode) {
      items.push(...exampleItems);
    }

    return { items };
  },

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem).filter((it) => !!it);
    },
    profile() {
      return {
        avatar: false, // not ready
        group: "",
        title: this.$store.getters.loginUserName,
        children: [
          {
            title: `${this.$t("logout")}`,
            click: () => this.logout(),
          },
          // {
          //   href: "",
          //   title: this.$t("my-profile"),
          // },
          // {
          //   to: "",
          //   title: this.$t("edit-profile"),
          // },
          // {
          //   to: "",
          //   title: this.$t("settings"),
          // },
        ],
      };
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    mapItem(item) {
      const { viewId, titleKey } = item;
      const title = (titleKey ? this.$t(titleKey) : "") || item.title;

      // 권한이 없는 viewId인 경우 표시하지 않음
      if (viewId && !this.$store.getters.isAllowedViewId(viewId)) {
        return undefined;
      }

      // 메뉴 그룹인 경우
      let children;
      if (item.children) {
        children = item.children.map(this.mapItem).filter((it) => !!it);

        // 하위 메뉴가 없는 경우 그룹을 표시하지 않음
        if (children.length === 0) {
          return undefined;
        }
      }

      return {
        ...item,
        children,
        title,
      };
    },
    logout() {
      console.log("LOGOUT");
      this.$msal.signOut();
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
